import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroPages from "../../components/heroes/hero-features";
import {Col, Container, Row} from "react-bootstrap";
import EventsData from "../../content/events/index.json";
import SimpleCard from "../../components/misc/simple-card";

const EventsIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo
        lang={intl.locale}
        title={EventsData[intl.locale].title}
        description={EventsData[intl.locale].text}
        pathname={location.pathname}
    />
    <HeroPages
      theme={`primary`}
      content={EventsData[intl.locale]}
    />
    <div className={`main-page-wrapper`}>
      <Container>
          <h2 className={`mb-4`}>{EventsData[intl.locale].upcoming.title}</h2>
          <Row>
              {EventsData[intl.locale].upcoming.events.map((item, i) => {
                  return (
                      <Col key={i} md={4}>
                          <SimpleCard content={item} />
                      </Col>
                  )
              })}
          </Row>
      </Container>
      <Container>
          <h2 className={`mb-4`}>{EventsData[intl.locale].past.title}</h2>
          <Row>
              {EventsData[intl.locale].past.events.map((item, i) => {
                  return (
                      <Col key={i} md={4}>
                          <SimpleCard content={item} />
                      </Col>
                  )
              })}
          </Row>
      </Container>
    </div>
  </Layout>
)

export default injectIntl(EventsIndexPage)
